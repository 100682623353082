import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {DataService} from '../shared/data.service';
import {NotificationsService} from "angular2-notifications";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('codeinput') codeInput:ElementRef;
  public loginQRCodeUrl: string = '';
  public loginStep: number = 1;
  public isHowVisible: number = 0;

  constructor(private ds: DataService,
              private router: Router,
              private notiService: NotificationsService
  ) { }

  ngOnInit() {
    this.getUser()
  }

  getUser() {
    this.ds.getCurrentUser().subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.router.navigate(['app/transactions']);
    })
  }

  onLogin(form: NgForm) {
    let guest = {
      username: form.value.email,
      password: form.value.password,
      code: null,
    };
    if (guest.username && guest.password) {

      if (this.loginStep === 2) {
        guest.code = form.value.otp
      }

      this.ds.login(guest).subscribe(this.onLoginRequestFinished.bind(this));
    } else {
      this.notiService.error('Error', 'Wrong Email or Password');
    }
  }

  onForgetPwd(form: NgForm) {
    let email = form.value.email;
    if (!email) {
      return this.notiService.error('Missing email', 'Please fill the email field')
    }
    this.ds.sendPwdResetMail(email).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      return this.notiService.success('Success', 'Password reset mail sent.');
    })
  }

  onForgetQr(form: NgForm) {
    let email = form.value.email;
    let password = form.value.password;
    if (!email || !password) {
      return this.notiService.error('Missing email or password', 'Please fill the email and password fields')
    }

    this.ds.sendQrResetMail(email).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      return this.notiService.success('Success', 'QR reset mail sent.');
    })
  }

  private onLoginRequestFinished(resp) {
    if (resp.error) {
      return this.notiService.error('Error', resp.error);
    }
    if (resp.url !== undefined) {
      this.loginStep = 2;
      this.loginQRCodeUrl = resp.url;
      setTimeout(() => { this.codeInput.nativeElement.focus(); }, 100);
    } else {
      this.getUser();
    }
  }
}
