import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../shared/data.service";
import {NotificationsService} from "angular2-notifications";

@Component({
  selector: 'app-qr-reset',
  templateUrl: './qr-reset.component.html',
  styleUrls: ['../../login/login.component.css']
})
export class QrResetComponent implements OnInit {

  constructor(
    private ds: DataService,
    private notiService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  token = '';

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.ds.postQr(params['token']).subscribe(resp => {
          if (resp.error) {
            return this.notiService.error('Error', resp.error);
          }
          this.notiService.success('Success', 'QR changed.');
          this.router.navigate(['/']);
        });
      } else {
        this.notiService.error('Error', 'QR reset token missing')
      }
    });
  }

}
