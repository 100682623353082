import {Component, ElementRef, OnInit} from '@angular/core';
import { Chart } from 'chart.js';
import {DataService} from '../shared/data.service';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.css']
})
export class DashboardsComponent implements OnInit {

  optionsChart: any;
  stocksChart: any;

  originalStocks = [];
  originalOptions = [];
  searchOption = '';
  searchStock = '';

  stocksChartData = {
    labels: [],
    datasets: [
      {
        borderWidth: 1,
        data: []
      }
    ]
  };

  optionsChartData = {
    labels: [],
    datasets: [
      {
        borderWidth: 1,
        data: []
      }
    ]
  };

  options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          return tooltipItem.xLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          }
        }
      }]
    }
  };

  constructor(private elementRef: ElementRef, private ds: DataService) { }

  ngOnInit() {

    this.ds.getDashboard().subscribe((resp: {stocks: Array<any>, options: Array<any>}) => {
      const chartData = resp;
      if (resp.stocks) {
        for (const [key, value] of Object.entries(chartData.stocks)) {
          this.originalStocks.push({
            name: key,
            value: value,
            hidden: false
          });
        }
        this.stocksChartData = this.buildChartData(this.originalStocks);
        this.makeStockChart();
      }

      if (resp.options) {
        for (const [key, value] of Object.entries(chartData.options)) {
          this.originalOptions.push({
            name: key,
            value: value,
            hidden: false
          });
        }
        this.optionsChartData = this.buildChartData(this.originalOptions);
        this.makeOptionsChart()
      }
    })
  }

  makeOptionsChart = () => {
    const optionsChartRef = this.elementRef.nativeElement.querySelector(`#optionsChart`);
    this.optionsChart = new Chart(optionsChartRef, {
      type: 'horizontalBar',
      data: this.optionsChartData,
      options: this.options
    });
  };

  makeStockChart = () => {
    const stocksChartRef = this.elementRef.nativeElement.querySelector(`#stocksChart`);
    this.stocksChart = new Chart(stocksChartRef, {
      type: 'horizontalBar',
      data: this.stocksChartData,
      options: this.options
    });
  };

  buildChartData = (list) => {
    const data = {
      labels: [],
      datasets: [
        {
          borderWidth: 1,
          data: []
        }
      ]
    };

    list.map(item => {
      if (!item.hidden) {
        data.labels.push(item.name);
        data.datasets[0].data.push(item.value);
      }
    });

    return data;
  };

  toogleItemInList = (type) => {
    if (type == "option") {
      this.optionsChartData = this.buildChartData(this.originalOptions);
      this.optionsChart.destroy();
      this.makeOptionsChart();
    } else if (type == "stock") {
      this.stocksChartData = this.buildChartData(this.originalStocks);
      this.stocksChart.destroy();
      this.makeStockChart();
    }
  }

}
