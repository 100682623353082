import { Component, OnInit } from '@angular/core';
import {DataService} from '../shared/data.service';
import {ExporterService} from '../shared/exporter.service';

@Component({
  selector: 'app-put-options',
  templateUrl: './put-options.component.html',
  styleUrls: ['./put-options.component.css']
})
export class PutOptionsComponent implements OnInit {

  options = [];
  allOptions = [];
  types = [];
  filters = {
    order: 'name',
    dest: 'asc',
    assettype: ''
  };

  constructor(
    private ds: DataService,
    private exporterService: ExporterService
  ) { }

  ngOnInit() {
    this.getPutOptions()
  }

  getPutOptions() {
    const params = `order=${this.filters.order}&dest=${this.filters.dest}`

    this.ds.getOptions(params).subscribe(resp => {
      this.types = resp.types ? resp.types : [];
      this.allOptions = resp.items ? resp.items : [];
      this.options = this.allOptions;
    })
  }

  onOrderByClicked(order) {
    this.filters.order = order;
    this.filters.dest = (this.filters.dest === 'asc' ? 'desc' : 'asc');

    this.getPutOptions()
  }

  onExportOptions(format) {
    this.exporterService.export('option', format);
  }

  onFilterTable() {
    if (!this.filters.assettype) {
      this.options = this.allOptions;
      return;
    }
    this.options = this.allOptions.filter(item => +item.asset.assettype.id === +this.filters.assettype);
  }
}
