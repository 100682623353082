import {Component, OnInit} from '@angular/core';
import {DataService} from '../shared/data.service';
import {NotificationsService} from 'angular2-notifications';
import {User} from "../shared/user.model";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor( private ds: DataService,
               private notiService: NotificationsService) { }

  profile: User;
  currentPassword: string;

  ngOnInit() {
    this.profile = new User();
    this.ds.getCurrentUser().subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.profile = Object.assign(this.profile, resp);
    });
  }

  onProfileSubmit() {
    if (this.profile.password || this.profile.old_password || this.profile.password_again) {
      if (this.profile.password !== this.profile.password_again) {
        return this.notiService.error('Error', 'Password missmatch');
      }
      if (!this.profile.old_password) {
        return this.notiService.error('Error', 'Current password is required');
      }
    }

    this.ds.saveProfile(this.profile).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.notiService.success('Success', 'Profile saved');
    })
  }
}
