import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '../../shared/data.service';
import {NotificationsService} from "angular2-notifications";
import {Asset} from "../../shared/asset.model";

@Component({
  selector: 'app-edit-option',
  templateUrl: './edit-option.component.html',
  styleUrls: ['./edit-option.component.css']
})
export class EditOptionComponent implements OnInit {

  constructor(
    private ds: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private notiService: NotificationsService
  ) { }

  option: Asset = {};
  currencies = [];
  types = [];

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params['id']) {
          this.ds.getOption(params['id'] === 'new' ? -1 : +params['id']).subscribe(resp => {
            this.currencies = resp.currencies ? resp.currencies : [];
            this.types = resp.types ? resp.types : [];
            this.option = resp.item ? resp.item : {};
            if (this.option['asset']) {
              this.option['name'] = this.option['asset'].name;
              this.option['assettype_id'] = this.option['asset'].assettype_id;
            }
          })
      }
    });
  }

  onOptionSubmit(form) {
    delete this.option['asset'];
    delete this.option['currency'];

    this.ds.saveOption(this.option).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.notiService.success('Success', 'Put option save');
      this.router.navigate(['app/options']);
    });
  }

  onDeleteOption() {
    this.ds.confirmDelete().subscribe(answer => {
      if (answer.resolved) {
        this.ds.deleteOption(this.option).subscribe(resp => {
          if (resp.error) {
            return this.notiService.error('Error', resp.error);
          }
          this.notiService.success('Success', 'Deleted');
          this.router.navigate(['app/options']);
        });
      }
    })
  }

}
