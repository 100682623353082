export class ApiEndpoints {
    static readonly profile = 'api-profile';
    static readonly users = 'api-users';
    static readonly activation = 'api-activation';
    static readonly options = 'api-put-option';
    static readonly stock = 'api-stock';
    static readonly stockSync = 'api-stock-sync';
    static readonly match = 'api-match';
    static readonly transaction = 'api-transaction';
    static readonly transactionSearch = 'api-transaction-search';
    static readonly banks = 'api-bank';
    static readonly dashboard = 'api-dashboard';
    static readonly export = 'api-export';
    static readonly pwdforget = 'api-password-forget';
    static readonly pwdreset = 'api-password-reset';
    static readonly qrforget = 'api-otp-forget';
    static readonly qrreset = 'api-otp-reset';
    static readonly assettype = 'api-asset-type';
}
