import { Component, OnInit } from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {DataService} from '../shared/data.service';
import {User} from '../shared/user.model';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  navCollapsed = true;

  constructor(private router: Router, private ds: DataService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.navCollapsed = true;
      }
    });
  }

  ngOnInit() {
  }

  isAdmin() {
    return this.ds.isCurrentUserAdmin();
  }

  onToggleNav() {
    this.navCollapsed = !this.navCollapsed;
  }

  onLogout() {
    this.ds.logout();
  }
}
