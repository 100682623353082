import { Component, OnInit } from '@angular/core';
import {DataService} from '../../shared/data.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NotificationsService} from "angular2-notifications";
import {Asset} from "../../shared/asset.model";

@Component({
  selector: 'app-edit-stock',
  templateUrl: './edit-stock.component.html',
  styleUrls: ['./edit-stock.component.css']
})
export class EditStockComponent implements OnInit {

  constructor(
    private ds: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private notiService: NotificationsService
  ) { }

  stock: Asset = {};
  newMatch = {name: ''};
  selectedMatch = {name: ''};
  searchText = '';
  currencies = [];
  markets = [];
  sectors = [];
  industries = [];
  matches = [];


  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        this.ds.getStock(params['id'] === 'new' ? -1 : +params['id']).subscribe(resp => {
          this.currencies = resp.currencies ? resp.currencies : [];
          this.markets = resp.markets ? resp.markets : [];
          this.sectors = resp.sectors ? resp.sectors : [];
          this.industries = resp.industries ? resp.industries : [];
          this.matches = resp.matches ? resp.matches : [];
          this.stock = resp.item ? resp.item : {
            currency_id: null,
            market_id: null,
            sector_id: null,
            industry_id: null,
            fsname_id: null,
            match_id: null
          };
          if (this.stock['asset']) {
            this.stock['name'] = this.stock['asset'].name;
          }
          if (this.stock.match_id) {
            this.matches.map(item => {
              if (item.id == this.stock.match_id) {
                this.selectMatch(item);
              }
            })
          }
        })
      } else {
        this.router.navigate(['app/stocks']);
      }
    });
  }

  cleanStockObjects() {
    delete this.stock['currency'];
    delete this.stock['asset'];
    delete this.stock['market'];
    delete this.stock['sector'];
    delete this.stock['industry'];
    delete this.stock['fsname'];
    delete this.stock['match'];
  }

  onStockSubmit(form) {
    this.cleanStockObjects();
    this.ds.saveStock(this.stock).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.notiService.success('Success', 'Stock save');
      this.router.navigate(['app/stocks']);
    });
  }

  onDeleteStock() {
    this.ds.confirmDelete().subscribe(answer => {
      if (answer.resolved) {
        this.ds.deleteStock(this.stock).subscribe(resp => {
          if (resp) {
            if (resp.error) {
              return this.notiService.error('Error', resp.error);
            }
            this.notiService.success('Success', 'Deleted');
            this.router.navigate(['app/stocks']);
          }
        });
      }
    });
  }

  /* Match dropdown */
  onDropdownOpen() {
    this.searchText = '';
    this.newMatch = {name: ''};
  }

  selectMatch(match) {
    this.selectedMatch = match;
    this.stock.match_id = match.id;
  }

  onAddNewMatch() {
    this.ds.saveMatch(this.newMatch).subscribe(resp => {
      if (resp && resp.id) {
        this.notiService.success('Success', 'Match saved');
        this.selectMatch(resp);
      }
    })
  }

  onMatchDelete(obj) {
    this.ds.confirmDelete().subscribe(answer => {
      if (answer.resolved) {
        this.ds.deleteMatch(obj).subscribe(resp => {
          if (resp) {
            if (resp.error) {
              return this.notiService.error('Error', resp.error);
            }
            this.notiService.success('Success', 'Deleted');
            this.matches.splice(this.matches.findIndex(item => +item.id === +obj.id), 1);
          }
        });
      }
    });
  }
}
