import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './nav/nav.component';
import { DashboardsComponent } from './dashboards/dashboards.component';
import {AppRoutingModule} from '../app-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { TransactionsComponent } from './transactions/transactions.component';
import {FormsModule} from '@angular/forms';
import {DataService} from './shared/data.service';
import {StorageService} from './shared/storage.service';
import { StocksComponent } from './stocks/stocks.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SimpleNotificationsModule} from 'angular2-notifications';
import { MyDatePickerModule } from 'mydatepicker';
import { EditStockComponent } from './stocks/edit-stock/edit-stock.component';
import { PutOptionsComponent } from './put-options/put-options.component';
import { EditOptionComponent } from './put-options/edit-option/edit-option.component';
import { UsersComponent } from './users/users.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import {DropdownModule} from "ngx-dropdown";
import {SearchFilterPipe} from "./shared/search-filter.pipe";
import {MomentModule} from "angular2-moment";
import {AuthGuardService} from "./shared/auth-guard.service";
import {LoginGuardService} from "./shared/login-guard.service";
import {HttpClientModule} from "@angular/common/http";
import {PagerService} from "./shared/pager.service";
import {AssettypeFilterPipe} from "./shared/assettype-filter.pipe";
import { ActivationComponent } from './users/activation/activation.component';
import {FileDownload} from "./shared/file-download.service";
import { QrResetComponent } from './users/qr-reset/qr-reset.component';
import { PwdResetComponent } from './users/pwd-reset/pwd-reset.component';
import {ExporterService} from './shared/exporter.service';
import {JasperoConfirmationsModule} from '@jaspero/ng-confirmations';
import {HotTableModule} from 'ng2-handsontable';

@NgModule({
  declarations: [
    AppComponent,
    SearchFilterPipe,
    AssettypeFilterPipe,
    LoginComponent,
    HomeComponent,
    NavComponent,
    DashboardsComponent,
    ProfileComponent,
    TransactionsComponent,
    StocksComponent,
    EditStockComponent,
    PutOptionsComponent,
    EditOptionComponent,
    UsersComponent,
    EditUserComponent,
    ActivationComponent,
    QrResetComponent,
    PwdResetComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    MomentModule,
    MyDatePickerModule,
    JasperoConfirmationsModule.forRoot(),
    DropdownModule,
    HotTableModule
  ],
  providers: [
    DataService,
    StorageService,
    AuthGuardService,
    LoginGuardService,
    PagerService,
    FileDownload,
    ExporterService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
