import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assettypefilter'
})

@Injectable()
export class AssettypeFilterPipe implements PipeTransform {
  transform(items: any[], value: string): any[] {
    if (!items) return [];
    if (!value) return items;

    return items.filter(item => {
      let assetID = +item['assettype'].id;
      if (!assetID) {
        assetID = +item['assettype_id'];
      }
      return (+value === assetID);
    })
  }
}
