import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
  constructor() {}

  get(key: string) {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
    return this;
  }

  remove(key: string) {
    localStorage.removeItem(key);
    return this;
  }
}
