import {Injectable} from "@angular/core";

@Injectable()
export class FileDownload {
  constructor() {}

  download(url) {
    let elem = window.document.createElement('a');
    elem.href = url;
    elem.target = '_blank';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);

  }
}
