import { Component, OnInit } from '@angular/core';
import {NgForm} from "@angular/forms";
import {DataService} from "../../shared/data.service";
import {NotificationsService} from "angular2-notifications";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['../../login/login.component.css']
})
export class ActivationComponent implements OnInit {

  constructor(
    private ds: DataService,
    private notiService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  token = '';

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.ds.checkActivationToken(params['token']).subscribe(resp => {
          if (resp.error) {
            return this.notiService.error('Error', resp.error);
          }
          this.token = params['token'];
        })
      } else {
        this.notiService.error('Error', 'Activation token missing')
      }
    });
  }

  onActivate(form: NgForm) {
    let guest = {
      password: form.value.password,
      password_again: form.value.password_again
    };
    if (guest.password && guest.password_again && (guest.password_again === guest.password)) {
      this.ds.postActivation(this.token, {'password': guest.password}).subscribe(resp => {
        if (resp.error) {
         return this.notiService.error('Error', resp.error);
        }
        this.notiService.success('Success', 'User account activated. You can log in.');
        this.router.navigate(['/']);
      });
    } else {
      this.notiService.error('Error', 'Password missmatch');
    }
  }
}
