import { Component, OnInit } from '@angular/core';
import {DataService} from '../shared/data.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users = [];

  constructor(private ds: DataService) { }

  ngOnInit() {
    this.ds.getUsers().subscribe(resp => {
      this.users = resp;
    })
  }

}
