import {Injectable} from '@angular/core';

@Injectable()
export class PagerService {
  constructor() {}

  getPager(totalPages, currentPage, pageSize?) {
    currentPage = currentPage || 1;
    pageSize = pageSize || 10;
    totalPages = totalPages || 1;

    let startPage, endPage;

    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    const startIndex = (currentPage -1) * pageSize;
    const endIndex = startIndex + pageSize - 1;

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return {
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
