export class User {
  constructor (
    public id?: number,
    public first_name?: string,
    public last_name?: string,
    public email?: string,
    public group_id?: number,
    public password?: string,
    public old_password?: string,
    public password_again?: string,
    public status?: number,
    public otp_disabled?: string
  ) {}

  public get fullname() {
    return this.first_name + ' ' + this.last_name;
  }

  public isAdmin() {
    return +this.group_id === 3;
  }
}
