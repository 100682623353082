import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {StorageService} from './storage.service';
import {User} from './user.model';
import {NotificationsService} from 'angular2-notifications';
import {ApiEndpoints} from './ApiEndpoints';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs/Observable";
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {HttpClient} from "@angular/common/http";
import {ConfirmationService} from '@jaspero/ng-confirmations';

@Injectable()
export class DataService {

  private currentUser: User;
  private userToken: string = null;
  private tokenParam: string = null;
  private serverUrl = environment.apiEndpoint;

  keyUser = 'tran-u';
  keyToken = 'tran-k';
  keyDate = 'tran-d';

  private dateLimit: number = 60 * 60 * 24 * 1000; // 1 day in milisec

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private notiService: NotificationsService,
    private confirmService: ConfirmationService
  ) { }

  ExportType = {
    stock: 'stock',
    option: 'put-option',
    transaction: 'transaction'
  };

  /**
   * USER
   */
  getUsers() {
    return this.getRequest(ApiEndpoints.users);
  }
  getUser(id) {
    return this.getRequest(`${ApiEndpoints.users}/${id}`);
  }
  saveUser(obj) {
    return obj['id'] ? this.putRequest(`${ApiEndpoints.users}/${obj.id}`, obj) : this.postRequest(ApiEndpoints.users, obj);
  }
  deleteUser(obj) {
    return this.deleteRequest(`${ApiEndpoints.users}/${obj.id}`)
  }
  checkActivationToken(token) {
    this.tokenParam = '';
    return this.getRequest(`${ApiEndpoints.activation}/${token}`)
  }
  postActivation(token, obj) {
    return this.postRequest(`${ApiEndpoints.activation}/${token}`, obj)
  }
  saveProfile(obj: User) {
    return this.postRequest(ApiEndpoints.profile, obj)
      .map((result: any) => {
          this.currentUser.first_name = obj.first_name;
          this.currentUser.last_name = obj.last_name;
          this.currentUser.email = obj.email;
          this.storage.set(this.keyUser, this.currentUser);
          return result;
      })
  }
  getCurrentUser(): any {
    this.userToken = this.userToken ? this.userToken : this.storage.get(this.keyToken);
    if (!this.userToken) {
      return Observable.empty<Response>();
    }

    // Check last login date
    const lastLogin = this.storage.get(this.keyDate);
    const today = new Date().getTime();
    if (today - lastLogin > this.dateLimit) {
      //this.notiService.error('Error', 'Session expired');
      this.logout();
      return Observable.empty<Response>();
    }

    this.tokenParam = `?_token=${this.userToken}`;
    if (this.currentUser && this.currentUser.id) {
      return Observable.of(this.currentUser);
    } else {
      this.currentUser = new User();
      let cachedUser = this.storage.get(this.keyUser);
      if (cachedUser && cachedUser.id) {
        this.currentUser = Object.assign(this.currentUser, cachedUser);
        return Observable.of(this.currentUser);
      } else {
        return this.getRequest(ApiEndpoints.profile).map(result => {
          if (result && result.id) { // We have the user profile
            this.currentUser = Object.assign(this.currentUser, result);
            this.storage.set(this.keyUser, this.currentUser);
            return this.currentUser;
          }
          return Observable.throw('Something went wrong. Profile missing');
        })
      }
    }
  }
  isCurrentUserAdmin() {
    return this.currentUser && this.currentUser.isAdmin();
  }


  /**
   * PASSWORD RESET
   * */

  sendPwdResetMail(email) {
    this.tokenParam = '';
    return this.postRequest(`${ApiEndpoints.pwdforget}`, {email: email})
  }
  checkPwdToken(token) {
    this.tokenParam = '';
    return this.getRequest(`${ApiEndpoints.pwdreset}/${token}`)
  }
  postPwd(token, obj) {
    return this.postRequest(`${ApiEndpoints.pwdreset}/${token}`, obj)
  }

  /**
   * QR RESET
   * */

  sendQrResetMail(email) {
    this.tokenParam = '';
    return this.postRequest(`${ApiEndpoints.qrforget}`, {email: email})
  }
  postQr(token) {
    this.tokenParam = '';
    return this.postRequest(`${ApiEndpoints.qrreset}/${token}`, {})
  }

  /**
   * STOCK
   */
  getStocks(params?: string) {
    return this.getRequest(ApiEndpoints.stock, `${params}`);
  }
  getStock(id: number) {
    return this.getRequest(`${ApiEndpoints.stock}/${id}`);
  }
  saveStock(obj) {
    return obj['id'] ? this.putRequest(`${ApiEndpoints.stock}/${obj.id}`, obj) : this.postRequest(ApiEndpoints.stock, obj);
  }
  deleteStock(obj) {
      return this.deleteRequest(`${ApiEndpoints.stock}/${obj.id}`)
  }
  saveMatch(obj) {
    return obj['id'] ? this.putRequest(`${ApiEndpoints.match}/${obj.id}`, obj) : this.postRequest(ApiEndpoints.match, obj);
  }
  deleteMatch(obj) {
    return this.deleteRequest(`${ApiEndpoints.match}/${obj.id}`)
  }
  syncAssets() {
    return this.getRequest((ApiEndpoints.stockSync));
  }

  /**
   * OTHER (PUT OPTION)
   */
  getOptions(params?: string) {
      return this.getRequest(ApiEndpoints.options, params);
  }
  getOption(id: number) {
      return this.getRequest(`${ApiEndpoints.options}/${id}`);
  }
  saveOption(obj) {
    return obj['id'] ? this.putRequest(`${ApiEndpoints.options}/${obj.id}`, obj) : this.postRequest(ApiEndpoints.options, obj);
  }
  deleteOption(obj) {
      return this.deleteRequest(`${ApiEndpoints.options}/${obj.id}`)
  }
  getDashboard() {
    return this.getRequest(ApiEndpoints.dashboard)
      .map((result: any) => {
        if (result.stocks && result.options) {
          const stocks = {};
          const options = {};

          if (result.stocks) {
            result.stocks.map(item => {
              stocks[item.asset_name] = item.volume;
            })
          }
          if (result.options) {
            result.options.map(item => {
              options[item.asset_name] = item.volume;
            })
          }
          return {stocks: stocks, options: options};
        }
        return Observable.throw('Something went wrong');
      })
  }

  /**
   * TRANSACTIONS
   */
  searchTransactions(params: any) {
    return this.postRequest(ApiEndpoints.transactionSearch, params);
  }
  getTransactionFilters() {
    return this.getRequest(ApiEndpoints.transaction)
  }
  getTransaction(id: number) {
    return this.getRequest(`${ApiEndpoints.transaction}/${id}`);
  }
  saveTransaction(obj) {
    return obj['id'] ? this.putRequest(`${ApiEndpoints.transaction}/${obj.id}`, obj) : this.postRequest(ApiEndpoints.transaction, obj);
  }
  deleteTransaction(obj) {
    return this.deleteRequest(`${ApiEndpoints.transaction}/${obj.id}`)
  }

  /**
   * CSV Export
   */

  exportAs(type, format, filter?) {
    return this.postRequest(`${ApiEndpoints.export}/${this.ExportType[type]}/${format}`, filter ? filter : {})
  }

  /**
   * SESSION
   */
  public requestToken(user){
      return this.http.post(`${this.serverUrl}/api-token`, user)
          .map((result: any) => {
              if (result.success) {
                  if (result.response.token){
                      this.userToken = result.response.token;
                      this.tokenParam = `?_token=${this.userToken}`;
                      this.storage.set(this.keyToken, this.userToken);
                      this.storage.set(this.keyDate, new Date().getTime());
                      return result.response;
                  }

                  if (result.response.url !== undefined) {
                    return result.response;
                  }

                  return Observable.throw('Invalid user');
              }
              return result.reason ? Observable.throw(result.reason) : Observable.throw('Something went wrong');
          })
  }

  isAuthenticated() {
    this.getCurrentUser();
    return this.currentUser && this.currentUser.id;
  }

  login(guest) {
    return this.requestToken(guest)
  }

  logout() {
    this.storage.remove(this.keyToken);
    this.storage.remove(this.keyUser);
    this.storage.remove(this.keyDate);
    this.userToken = null;
    this.tokenParam = null;
    this.currentUser = null;
    this.router.navigate(['/']);
  }

  // real http calls
  public postRequest(endpoint: string, data: any) {
      return this.handleFinishedRequest(
          this.http
              .post(`${this.serverUrl}/${endpoint}${this.tokenParam}`, JSON.stringify(data))
      );
  }

  public putRequest(endpoint: string, data: any) {
      return this.handleFinishedRequest(
          this.http
              .put(`${this.serverUrl}/${endpoint}/${data.id}${this.tokenParam}`, data)
      );
  }

  public getRequest(endpoint: string, params?: string) {
      let url_params = '';
      if (params) {
        url_params = `&${params}`
      }
      return this.handleFinishedRequest(
          this.http
              .get(`${this.serverUrl}/${endpoint}${this.tokenParam}${url_params}`)
      );
  }

  public getRawRequest(url: string, options: any) {
    return this.http.get(url, options);
  }

  public confirmDelete() {
    return this.confirmService.create('Confirm', 'Are you sure to delete?')
  }

  protected deleteRequest(endpoint: string)
  {
      return this.handleFinishedRequest(
          this.http
              .delete(`${this.serverUrl}/${endpoint}${this.tokenParam}`)
      );
  }

  protected handleFinishedRequest(observable: any)
  {
      return observable
          .map((result: any) => {
              if (result.success) {
                  return result.response;
              }
              return result.reason ? Observable.throw(result.reason) : Observable.throw('Something went wrong');
          })
          .catch(
              (error: Response) => {
                  return Observable.throw('Something went wrong');
              }
          );
  }
}
