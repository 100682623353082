import {Injectable} from "@angular/core";
import {DataService} from "./data.service";
import {CanActivate, Router} from "@angular/router";

@Injectable()
export class LoginGuardService implements CanActivate{
    constructor (
        private ds: DataService,
        private router: Router
    ) {}

    canActivate(): boolean {
        if (!this.ds.isAuthenticated()){
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}