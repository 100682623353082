import {Component, OnInit} from '@angular/core';
import {DataService} from '../shared/data.service';
import {ExporterService} from '../shared/exporter.service';
import {NotificationsService} from 'angular2-notifications';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit {

  stocks = [];
  nativeWindow: any;
  filters = {
    order: 'name',
    dest: 'asc'
  }
  constructor(
    private ds: DataService,
    private exporterService: ExporterService,
    private notiService: NotificationsService,
  ) {

  }

  ngOnInit() {
    this.getStocks()
  }

  getStocks() {
    let params = `order=${this.filters.order}&dest=${this.filters.dest}`
    this.ds.getStocks(params).subscribe(resp => {
      this.stocks = resp;
    })
  }

  onSyncAssets() {
    this.notiService.success('Started', 'Asset sync started. Please wait');
    this.ds.syncAssets().subscribe(resp => {
      if (resp) {
        this.notiService.success('Finished', 'Asset sync finished.');
      }
    })
  }

  onOrderByClicked(order) {
    this.stocks = []
    this.filters.order = order;
    this.filters.dest = (this.filters.dest === 'asc' ? 'desc' : 'asc')

    this.getStocks()
  }

  onExportStocks(format) {
    this.exporterService.export('stock', format);
  }
}
