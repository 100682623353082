import {Component, OnInit} from '@angular/core';
import {DataService} from '../shared/data.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {IMyDateModel, IMyDpOptions} from 'mydatepicker';
import {NotificationsService} from 'angular2-notifications';
import * as moment from 'moment';
import {PagerService} from '../shared/pager.service';
import {ExporterService} from '../shared/exporter.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  itemsPerPage = 20;
  putOptionID = 2;
  putOptionFeeVisible = false;

  pager = {
    totalPages: 1,
    currentPage: 1,
    pages: []
  };

  transactions = [];
  editMode = false;
  bulkImportView = false;
  isNew = false;
  hasErrors = false;
  showStockSelector = false;

  stocks: any;
  filteredStocks: any;
  filteredBulkStocks: any;
  account_types = [];
  banks = [];
  currencies = [];
  asset_types = [];
  companies = [];
  actions = ['Buy', 'Sell', 'Stock Split', 'Transfer In', 'Transfer Out', 'Starting Balance', 'Dividend'];
  requiredPutOptionFeeList = [ // These asset types require put option fee
    2,  // Put Option
    3,  // Bond
    7,  // Dividend
    8   // Call option
  ];

  cacheTransaction = {};

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    openSelectorOnInputClick: true,
    showSelectorArrow: false,
    editableDateField: false,
      showClearDateBtn: true
  };

  searchText = '';
  priceDefault = 0;
  newStock = {};
  selectedStock = {};
  filters: any;

  private bulkData: any[] = [];

  private colHeaders: string[] = [
    'Errors',
    'Date of transaction',
    'Bank',
    'Deposit number',
    'Account Type',
    'Company holding the account',
    'Stock',
    'Type of Asset',
    'Currency',
    'Exchange rate',
    'Transaction Price/Local currency',
    'Action',
    'Volume',
    'Transaction Value',
    'Transaction fee'
  ];

  private columns: any[] = [
    { data: 'errors', readOnly: true, skipColumnOnPaste: true },
    { data: 'date_of_transaction' },
    { data: 'bank' },  // bank_id
    { data: 'deposit_number' },
    { data: 'accounttype' }, //accounttype_id
    { data: 'company' }, //company_id
    { data: 'asset' }, //asset_id [STOCK]
    { data: 'assettype' }, //assettype_id
    { data: 'currency' }, //currency_id
    { data: 'exchange_rate' },
    { data: 'transaction_price_local_currency' },
    { data: 'action' },
    { data: 'volume' },
    { data: 'transaction_value' },
    { data: 'transaction_fee' },
  ];


  private options: any = {
    stretchH: 'all',
    columnSorting: false,
    contextMenu: [
      'row_above', 'row_below', 'remove_row'
    ],
    minSpareRows: 10,
  };

  constructor(
      private ds: DataService,
      private route: ActivatedRoute,
      private router: Router,
      private notiService: NotificationsService,
      private pagerService: PagerService,
      private exporterService: ExporterService,
  ) {}

  ngOnInit() {
    this.initFilters();
    this.route
      .queryParams
      .subscribe(params => {
        this.pager.currentPage = +params['p'] || 1;
      });
    this.route.params.subscribe( (params: Params) => {
      if (params['action'] && params['action'] === 'new') {
        this.isNew = true;
      }
    });
    this.searchTransactions(this.getSearchFilters());
  }

  initFilters()
  {
    this.resetFilters();
    this.ds
      .getTransactionFilters()
      .subscribe(data => {
        this.banks = data.banks ? data.banks : [];
        this.account_types = data.account_types ? data.account_types : [];
        this.currencies = data.currencies ? data.currencies : [];
        this.companies = data.companies ? data.companies : [];
        this.asset_types = data.asset_types ? data.asset_types : [];
        this.stocks = this.orderArrayByName(data.stocks);
        this.filteredStocks = this.stocks;
        this.filteredBulkStocks = this.stocks.filter(s => +s.assettype_id === 1);
      });
  };

  getSearchFilters()
  {
    return {
      filters: {
        bank_id: this.filters.bank_id ? [this.filters.bank_id] : [],
        company_id: this.filters.company_id ? [this.filters.company_id] : [],
        currency_id: this.filters.currency_id ? [this.filters.currency_id] : [],
        assettype_id: this.filters.assettype_id ? [this.filters.assettype_id] : [],
        from: this.filters.from ? this.filters.from : '',
        to: this.filters.to ? this.filters.to : '',
        action: this.filters.action ? this.filters.action : ''
      },
      page: this.pager.currentPage,
      search_term: this.filters.search_term ? this.filters.search_term : '',
      items_per_page: this.itemsPerPage,
      order: this.filters.order,
      dest: this.filters.dest,
    };
  }

  fixNumberFields(transactions) {
    transactions.map(item => {
      item.volume = parseFloat(item.volume );
      item.transaction_price_local_currency = parseFloat(item.transaction_price_local_currency );
      item.transaction_value = parseFloat(item.transaction_value );
      item.transaction_fee = parseFloat(item.transaction_fee );
      item.putoption_fee = parseFloat(item.putoption_fee );
      item.exchange_rate = item.exchange_rate ? parseFloat(item.exchange_rate) : 1;
    });
    return transactions;
  }

  setScrollBar() {
    const table = document.getElementById('tran-table');
    const scroller = document.getElementById('tran-scroll');
    const scrollerRow = document.getElementById('tran-scroll-row');
    const tableRow = document.getElementById('tran-table-header');

    setTimeout(() => {
      scrollerRow.setAttribute("style",`width:${window.getComputedStyle(tableRow).width}`);
    }, 200);

    table.onscroll = () => {
      scroller.scrollLeft = table.scrollLeft;
    };
    scroller.onscroll = () => {
      table.scrollLeft = scroller.scrollLeft;
    };
  }

  searchTransactions(params) {
    this.ds.searchTransactions(params).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.transactions = this.fixNumberFields(resp.items);
      this.pager = this.pagerService.getPager(+resp.total_pages, this.pager.currentPage);
      if (this.isNew) {
        this.transactions.unshift({editing: true}); // Add new item
        this.editTransaction(this.transactions[0]);
      }
      this.setScrollBar();
    });
  }

  hasFilters() {
    return this.filters.bank_id || this.filters.currency_id || this.filters.company_id || this.filters.assettype_id || this.filters.from || this.filters.to || this.filters.search_term || this.filters.action;
  }

  resetFilters() {
    this.filters = {
      bank_id: '',
      company_id: '',
      currency_id: '',
      assettype_id: '',
      fromDate: '',
      toDate: '',
      from: '',
      to: '',
      search_term: '',
      order: 'date',
      dest: 'desc',
      action: ''
    };
  }

  onOrderByClicked(order) {
    this.transactions = []
    this.filters.order = order;
    this.filters.dest = (this.filters.dest === 'asc' ? 'desc' : 'asc')

    this.onFilterChange()
  }

  resetCacheTransaction() {
    this.cacheTransaction = {};
    this.priceDefault = 0;
  }

  onNewTransactionClick() {
    this.isNew = true;
    this.searchTransactions(this.getSearchFilters());
  }

  disableEdit(obj?) {
    if (obj && obj.editing) {
      obj.editing = false;
      if (!obj.id) {
        this.transactions.splice(0, 1); // Remove new item
        this.router.navigate(['app/transactions']);
      }
    }
    this.resetCacheTransaction();
    this.editMode = false;
    this.isNew = false;
  }

  orderArrayByName(input_array) {
    return input_array ? input_array.sort((a, b) => a.name.localeCompare(b.name)) : [];
  }

  updatePriceDefault() {
    if (!this.cacheTransaction['volume'] || !this.cacheTransaction['transaction_price_local_currency'] || !this.cacheTransaction['exchange_rate'] || !this.cacheTransaction['transaction_fee']) {
      return;
    }
    this.priceDefault = (
      this.cacheTransaction['volume'] *
      this.cacheTransaction['transaction_price_local_currency'] *
      this.cacheTransaction['exchange_rate']
    ) + (this.cacheTransaction['transaction_fee'] * this.cacheTransaction['exchange_rate']);
  }

  prepareObject(obj) {
    this.ds.getTransaction(obj.id).subscribe(resp => {
      this.stocks = this.orderArrayByName(resp.stocks);
      this.cacheTransaction = resp.item;
      this.cacheTransaction['exchange_rate'] = this.cacheTransaction['exchange_rate'] ? parseFloat(this.cacheTransaction['exchange_rate']) : 1;
      this.cacheTransaction['date_of_transaction'] = {
        epoc: moment(this.cacheTransaction['date_of_transaction']),
        formatted: this.cacheTransaction['date_of_transaction']
      };

      // Total purchase price default
      this.updatePriceDefault();

      this.selectedStock = this.stocks.filter(item => item.id === this.cacheTransaction['asset_id'])[0];
      this.putOptionFeeVisible = +this.cacheTransaction['assettype_id'] === +this.putOptionID;
      obj.editing = true;
      this.editMode = true;
      this.filterAssets(this.cacheTransaction['assettype_id']);
    });
  }

  onDefaultPriceClick() {
    this.cacheTransaction['purchase_total_price'] = Math.round(this.priceDefault);
  }

  editTransaction(obj) {
    if (this.editMode) {
      return;
    }
    if (obj.id) {
      this.prepareObject(obj);
    } else {
      obj.editing = true;
      this.editMode = true;
    }
  }

  isValidTransaction() {
    const fields = [];
    if (this.cacheTransaction['date_of_transaction'] === undefined) { fields.push('Date of transaction')}
    if (this.cacheTransaction['bank_id'] === undefined) { fields.push('Bank')}
    if (this.cacheTransaction['deposit_number'] === undefined) { fields.push('Deposit number')}
    if (this.cacheTransaction['accounttype_id'] === undefined) { fields.push('Account type')}
    if (this.cacheTransaction['assettype_id'] === undefined) { fields.push('Type of asset')}
    if (this.cacheTransaction['company_id'] === undefined) { fields.push('Company')}
    if (this.cacheTransaction['asset_id'] === undefined) { fields.push('Asset')}
    if (this.cacheTransaction['currency_id'] === undefined) { fields.push('Currency')}
    if (this.cacheTransaction['transaction_price_local_currency'] === undefined) { fields.push('Transaction price local currency')}
    if (this.cacheTransaction['action'] === undefined) { fields.push('Action')}
    if (this.cacheTransaction['volume'] === undefined) { fields.push('Volume')}
    if (this.cacheTransaction['transaction_value'] === undefined) { fields.push('Transaction value')}
    if (this.cacheTransaction['transaction_fee'] === undefined) { fields.push('Transaction fee')}

    // Other asset, put option fee required (except [stock, stock bta, clo])
    if (this.cacheTransaction['assettype_id'] && this.requiredPutOptionFeeList.includes(+this.cacheTransaction['assettype_id'])) {
      if (this.cacheTransaction['putoption_fee'] === undefined || this.cacheTransaction['putoption_fee'] === null) { fields.push('Putoption fee')}
    }

    if (fields.length === 0) {
      return true
    } else {
      this.notiService.error('Error', 'Missing field: ' + fields.join(', '));
      return false;
    }
  }

  saveTransaction(obj) {
    if (!this.isValidTransaction()) {
      return;
    }
    this.cacheTransaction['date_of_transaction'] = this.cacheTransaction['date_of_transaction'].formatted;
    this.ds.saveTransaction(this.cacheTransaction).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.notiService.success('Success', 'Transaction saved');
      this.editMode = false;
      this.isNew = false;
      this.cacheTransaction = {};
      if (!obj.id) {
        this.router.navigate(['app/transactions']);
      }
      this.searchTransactions(this.getSearchFilters());
    });
  }

  deleteTransaction(obj) {
    this.ds.confirmDelete().subscribe(answer => {
      if (answer.resolved) {
        this.ds.deleteTransaction(obj).subscribe(resp => {
          if (resp) {
            if (resp.error) {
              return this.notiService.error('Error', resp.error);
            }
            this.notiService.success('Success', 'Deleted');
            this.transactions.splice(this.transactions.findIndex(item => +item.id === +obj.id), 1);
          }
        });
      }
    });
  }

  onFilterChange() {
    this.resetPagination();
    this.searchTransactions(this.getSearchFilters());
  }

  filterAssets(typeID) {
    this.filteredStocks = this.stocks.filter(stock => +stock.assettype_id === +typeID);
  }

  onAssetTypeChange() {
    this.selectedStock = {};
    this.cacheTransaction['asset_id'] = undefined;
    this.putOptionFeeVisible = this.requiredPutOptionFeeList.includes(+this.cacheTransaction['assettype_id']);
    this.filterAssets(this.cacheTransaction['assettype_id'])
  }
  onDateChange(event: IMyDateModel) {
    if (event.formatted) {
      const today = moment();
      const action = moment(event.formatted);
      if (today.diff(action) < 0) {
        this.notiService.warn('Warning','You selected a future date');
      }
    }
  }

  datepickerChanged(event: IMyDateModel, type) {
    this.filters[type] = event.formatted;
    this.onFilterChange();
  }

  changePage(nextPage){
    if (nextPage < 1 || nextPage > this.pager.totalPages || this.editMode || this.isNew) {
      return;
    }

    // get pager from service
    this.pager = this.pagerService.getPager(this.pager.totalPages, nextPage);

    if (this.pager.currentPage > 1) {
      this.router.navigate(['app/transactions'], { queryParams: { p: this.pager.currentPage} });
    } else {
      this.router.navigate(['app/transactions']);
    }

    this.searchTransactions(this.getSearchFilters());
  }

  resetPagination(){
    if (this.pager.currentPage > 1) {
      this.pager.currentPage = 1;
      this.router.navigate(['app/transactions']);
    }
  }

  onExportTransactions(format) {
    this.exporterService.export('transaction', format, this.getSearchFilters());
  }

  /* Dropdown  */
  onDropdownOpen() {
    this.searchText = '';
    this.newStock = {};
    if (!this.cacheTransaction['assettype_id']) {
      this.notiService.warn('Warning', 'Type of Asset missing. It is now set to Name on Asset');
      this.cacheTransaction['assettype_id'] = '1';
      this.filterAssets(this.cacheTransaction['assettype_id'])
    }
  }

  selectAsset(asset) {
    this.selectedStock = asset;
    this.cacheTransaction['asset_id'] = asset.id;
    if (asset.currency && asset.currency.id) {
      this.cacheTransaction['currency_id'] = asset.currency.id;
    }
  }

  addAssetToStocks(response) {
    const asset = response.asset;

    asset.assettype = {
      id: asset.assettype_id
    };
    asset.symbol = response.symbol;

    this.stocks.push(asset);
    this.filterAssets(this.cacheTransaction['assettype_id']);
  }

  onAddNewStock() {
    if (!this.cacheTransaction['assettype_id']) {
        return this.notiService.error('Error', 'Transaction\'s Type of asset missing');
    }
    if (this.newStock['name'] && this.newStock['symbol']) {
        if ((+this.cacheTransaction['assettype_id'] === 1) || (+this.cacheTransaction['assettype_id'] === 4)){
            this.ds.saveStock({
                name: this.newStock['name'],
                isin: this.newStock['symbol']
            }).subscribe(resp => {
              if (resp.error) {
                return this.notiService.error('Error', resp.error);
              }
              this.notiService.success('Success', 'Name on Asset saved');
              this.selectAsset(resp.asset);
              this.addAssetToStocks(resp)
            });
        } else {
          this.newStock['assettype_id'] = this.cacheTransaction['assettype_id']; // Select put-option, Bond, CLO etc.
            this.ds.saveOption(this.newStock).subscribe(resp => {
              if (resp.error) {
                return this.notiService.error('Error', resp.error);
              }
              this.notiService.success('Success', 'Put option saved');
              this.selectAsset(resp.asset);
              this.addAssetToStocks(resp)
            });
        }
    } else {
        return this.notiService.error('Error', 'Name or isin missing');
    }
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onBulkAssetSelect(stock) {
    this.searchText = '';
    if (stock.name) {
      this.copyMessage(stock.name);
      this.notiService.success('Success', 'Asset name copied to clipboard. Paste it in the bulk table.');
    }
  }

  onBulkImportClick() {
    this.bulkImportView = true;
  }
  onBulkImportCancelClick() {
    window.location.reload();
  }

  onSaveBulk() {
    this.bulkData.map((d: any) => {
      if (d && d.date_of_transaction) {

        const defaultPrice = (
          d['volume'] *
          d['transaction_price_local_currency'] *
          d['exchange_rate']
        ) + (d['transaction_fee'] * d['exchange_rate']);

        if (defaultPrice) {
          d.purchase_total_price = defaultPrice;
        }

        this.ds.saveTransaction(d).subscribe(resp => {
          if (resp.error) {
            this.notiService.error('Error', resp.error);
          }
          this.notiService.success('Success', 'Transaction saved');
        });
      }
    })
  }

  resetBulkTable() {
    this.hasErrors = false;
    this.bulkData = [];
  }



  getIdFromList(list, name) {
    const nameTrim = name.trim().toLowerCase();
    const filteredList = list.filter(c => c.name.trim().toLowerCase() === nameTrim);
    if (filteredList.length) {
      return filteredList[0].id;
    }
    return null;
  }

  onConvertToTransactionsClick() {
    /*{
      "id": "2155",
      "date_of_transaction": {
      "epoc": "2022-09-20T22:00:00.000Z",
        "formatted": "2022-09-21"
    },
      "bank_id": "4",
      "deposit_number": "1010294679",
      "accounttype_id": "2",
      "company_id": "2",
      "asset_id": "205",
      "assettype_id": "1",
      "currency_id": "1",
      "transaction_price_local_currency": "13.13",
      "action": "Sell",
      "volume": "302500",
      "transaction_value": "3965043",
      "transaction_fee": "5956",
      "putoption_fee": null,
      "stock_id": null,
      "exchange_rate": 1,
      "purchase_total_price": "-3977781"
    }*/

    this.hasErrors = false;
    this.showStockSelector = false;
    this.bulkData = this.bulkData.map((d: any) => {
      if (d && d.date_of_transaction) {
        let errors = '';

        // if the date has 'DD/MM/YYYY' format, convert it to 'YYYY-MM-DD'
        if (d.date_of_transaction.indexOf('/') > -1) {
          const dateParts = d.date_of_transaction.split('/');
          d.date_of_transaction = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        }

        if (!moment(d.date_of_transaction, 'YYYY-MM-DD',true).isValid()) {
          errors += "date ";
          this.hasErrors = true;
        }


        d.company_id = this.getIdFromList(this.companies, d.company);
        if (!d.company_id) {
          errors += "company ";
          this.hasErrors = true;
        }
        d.bank_id = this.getIdFromList(this.banks, d.bank);
        if (!d.bank_id) {
          errors += "bank ";
          this.hasErrors = true;
        }
        d.currency_id = this.getIdFromList(this.currencies, d.currency);
        if (!d.currency_id) {
          errors += "currency ";
          this.hasErrors = true;
        }
        d.accounttype_id = this.getIdFromList(this.account_types, d.accounttype);
        if (!d.accounttype_id) {
          errors += "account_type ";
          this.hasErrors = true;
        }
        d.asset_id = this.getIdFromList(this.stocks, d.asset);
        if (!d.asset_id) {
          errors += "stock ";
          this.hasErrors = true;
          this.showStockSelector = true;
        }
        d.assettype_id = this.getIdFromList(this.asset_types, d.assettype);
        if (!d.assettype_id) {
          errors += "asset_type ";
          this.hasErrors = true;
        }

        if (this.actions.includes(d.action)) {
          // nothing to do
        } else {
          errors += "action "
          this.hasErrors = true;
        }

        if (d.transaction_fee) {
          d.transaction_fee = parseFloat(`${d.transaction_fee}`.replace(/ /g, ''));
        }
        if (d.transaction_value) {
          d.transaction_value = parseFloat(`${d.transaction_value}`.replace(/ /g, ''));
        }
        if (d.volume) {
          d.volume = parseFloat(`${d.volume}`.replace(/ /g, ''));
        }
        if (d.transaction_price_local_currency) {
          d.transaction_price_local_currency = parseFloat(`${d.transaction_price_local_currency}`.replace(/ /g, '').replace(/,/g, '.'));
        }
        if (d.exchange_rate) {
          d.exchange_rate = parseFloat(`${d.exchange_rate}`.replace(/ /g, '').replace(/,/g, '.'));
          if (isNaN(+d.exchange_rate)) {
            errors += "exchange ";
            this.hasErrors = true;
          } else {
            d.exchange_rate = parseFloat(`${d.exchange_rate}`.replace(/ /g, '').replace(/,/g, '.'));
          }
        } else {
          d.exchange_rate = 1;
        }

        d.errors = errors;

        return d;
      }
    })
  }
}
