import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './app/login/login.component';
import {HomeComponent} from './app/home/home.component';
import {DashboardsComponent} from './app/dashboards/dashboards.component';
import {TransactionsComponent} from './app/transactions/transactions.component';
import {ProfileComponent} from './app/profile/profile.component';
import {StocksComponent} from './app/stocks/stocks.component';
import {EditStockComponent} from './app/stocks/edit-stock/edit-stock.component';
import {PutOptionsComponent} from './app/put-options/put-options.component';
import {EditOptionComponent} from './app/put-options/edit-option/edit-option.component';
import {UsersComponent} from './app/users/users.component';
import {EditUserComponent} from './app/users/edit-user/edit-user.component';
import {AuthGuardService} from "./app/shared/auth-guard.service";
import {LoginGuardService} from "./app/shared/login-guard.service";
import {ActivationComponent} from "./app/users/activation/activation.component";
import {QrResetComponent} from "./app/users/qr-reset/qr-reset.component";
import {PwdResetComponent} from "./app/users/pwd-reset/pwd-reset.component";

const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'activate',
    component: ActivationComponent
  },
  {
    path: 'reset-password',
    component: PwdResetComponent
  },
  {
    path: 'reset-otp',
    component: QrResetComponent
  },
  {
    path: 'app',
    component: HomeComponent,
    canActivate: [LoginGuardService],
    children: [
      {
        path: 'dash',
        component: DashboardsComponent
      },
      {
        path: 'transactions',
        children: [
          {
            path: '',
            component: TransactionsComponent
          },
          {
            path: ':action',
            component: TransactionsComponent
          }
        ]
      },
      {
        path: 'stocks',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: StocksComponent
          },
          {
            path: ':id',
            component: EditStockComponent
          }
        ]
      },
      {
        path: 'options',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: PutOptionsComponent
          },
          {
            path: ':id',
            component: EditOptionComponent
          }
        ]
      },
      {
        path: 'users',
        canActivate: [AuthGuardService],
        children: [
          {
            path: '',
            component: UsersComponent
          },
          {
            path: ':id',
            component: EditUserComponent
          }
        ]
      },
      {
        path: 'profile',
        component: ProfileComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'app/transactions'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
