import {Injectable} from "@angular/core";
import {DataService} from "./data.service";
import {CanActivate, Router} from "@angular/router";

@Injectable()
export class AuthGuardService implements CanActivate{
    constructor (
        private ds: DataService,
        private router: Router
    ) {}

    canActivate(): boolean {
        if (!this.ds.isCurrentUserAdmin()){
            this.router.navigate(['app/transactions']);
            return false;
        }
        return true;
    }
}