import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchfilter'
})

@Injectable()
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], value: string, field: string[]): any[] {
    if (!items) return [];
    if (!value) return items;
    value = value.toLowerCase();

    return items.filter(item => {
      if (field.length > 0) {
        let found = false;
        field.map( f => {
          if (item[f]) {
            found = found ? found : item[f].toLowerCase().includes(value)
          }
        });
        return found;
      } else {
        return item.toLowerCase().includes(value);
      }
    });
  }
}
