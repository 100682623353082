import {Injectable} from '@angular/core';
import {FileDownload} from './file-download.service';
import {DataService} from './data.service';
import {NotificationsService} from 'angular2-notifications';
import {ConfirmationService} from '@jaspero/ng-confirmations';

@Injectable()
export class ExporterService {
  constructor(
    private downloaderService: FileDownload,
    private ds: DataService,
    private notificationService: NotificationsService,
    private confirmService: ConfirmationService
  ) {}

  export(type, format, filters?) {
    document.querySelector('.loader').classList.toggle('hidden');
    this.ds.exportAs(type, format, filters).subscribe(resp => {
      document.querySelector('.loader').classList.toggle('hidden');
      if (resp.error) {
        return this.notificationService.error('Error', resp.error);
      }

      this.confirmService.create('Export is ready',
        'You can download the file from this link: ' +
        '<p class="mt-4 mb-1"><a class="btn btn-info btn-block" href="' + resp + '" target="_blank">Download</a></p>',
        {
          showCloseButton: false,
          confirmText: 'Close',
          declineText: null,
          overlayClickToClose: false
        }
      );
    });
  }
}
