import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService} from '../../shared/data.service';
import {NotificationsService} from "angular2-notifications";
import {User} from "../../shared/user.model";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  constructor(
    private ds: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private notiService: NotificationsService
  ) { }

  groups = [];
  statuses = [];
  banks = [];
  user:User;

  ngOnInit() {
    this.user = new User();
    this.route.params.subscribe((params: Params) => {
      if (params['id']) {
        this.ds.getUser(params['id'] === 'new' ? -1 : +params['id']).subscribe(resp => {
          this.groups = resp.groups ? resp.groups : [];
          this.statuses = resp.statuses ? resp.statuses : [];
          this.banks = resp.banks ? resp.banks : [];

          if (resp.item) {
            this.user = Object.assign(this.user, resp.item);
            if (!this.user.status) {
              this.user.status = 0;
            }
          }
        })
      } else {
        this.router.navigate(['app/users']);
      }
    });
  }

  onUserSubmit(form) {
    delete this.user['banks'];
    delete this.user['group'];
    this.ds.saveUser(this.user).subscribe(resp => {
      if (resp.error) {
        return this.notiService.error('Error', resp.error);
      }
      this.notiService.success('Success', 'User save');
      this.router.navigate(['app/users']);
    })
  }

  onDeleteUser() {
    this.ds.confirmDelete().subscribe(answer => {
      if (answer.resolved) {
        this.ds.deleteUser(this.user).subscribe(resp => {
          if (resp) {
            if (resp.error) {
              return this.notiService.error('Error', resp.error);
            }
            this.notiService.success('Success', 'Deleted');
            this.router.navigate(['app/users']);
          }
        });
      }
    })
  }

  isUserGroupSelected() {
    return +this.user.group_id === 2;  // User group ID = 2
  }
}
